<template>
    <b-col
        v-if="sidebarIsVisible"
        cols="12"
        md="3"
        class="sidebar page mt-40"
    >
        <div class="sidebar-container">
            <my-account-navbar />
        </div>
    </b-col>
</template>

<script>
    import { mapGetters } from 'vuex';

    import MyAccountNavbar from '../controls/navbar/MyAccountNavbar';
    export default {
        components: { MyAccountNavbar },
        name: 'Sidebar',
        data() {
            return {
                screen: 0,
            };
        },
        computed: {
            ...mapGetters('auth', ['isLogged']),
            sidebarIsVisible() {
                return this.isLogged && this.$screen.width >= 768;
            },
        },
    };
</script>
