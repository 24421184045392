<template>
    <ul>
        <li
            v-if="isLogged && !isResponsive"
            :class="{ active: isActive('ContulMeu') }"
        >
            <router-link :to="{ name: 'ContulMeu' }">
                {{ $t('page.myAccount.heading') }}
            </router-link>
        </li>
        <li
            v-if="showNav"
            @click="toggleMyNav()"
        >
            <arrow-left-icon />
            {{ $t('header.navbar.myAccount') }}
        </li>
        <li
            v-if="isLogged && showNav"
            :class="{ active: isActive('ContulMeu') }"
        >
            <router-link :to="{ name: 'ContulMeu' }">
                {{ $t('header.navbar.myData') }}
            </router-link>
        </li>
        <li
            v-if="!isLogged && showNav"
            @click="showLoginPopup()"
        >
            {{ $t('header.navbar.login') }}
        </li>
        <li
            v-if="!isLogged && showNav"
            @click="showRegistrationPopup()"
        >
            {{ $t('header.navbar.registration') }}
        </li>
        <li
            v-if="isLogged"
            :class="{ active: isActive('AdreseleMele') }"
        >
            <router-link :to="{ name: 'AdreseleMele' }">
                {{ $t('page.myAddresses.heading') }}
            </router-link>
        </li>
        <li :class="{ active: isActive('Favorite') }">
            <router-link :to="{ name: 'Favorite' }">
                {{ $t('page.wishlist.heading') }}
            </router-link>
        </li>
        <li
            v-if="isLogged"
            :class="{ active: isActive('Comenzi') }"
        >
            <router-link :to="{ name: 'Comenzi' }">
                {{ $t('page.ordersHistory.listing.heading') }}
            </router-link>
        </li>
        <li
            v-if="isLogged"
            :class="{ active: isActive('CupoaneleMele') }"
        >
            <router-link :to="{ name: 'CupoaneleMele' }">
                {{ $t('page.myPromoCodes.heading') }}
            </router-link>
        </li>
        <li
            v-if="isLogged"
            :class="{ active: isActive('ReviewurileMele') }"
        >
            <router-link :to="{ name: 'ReviewurileMele' }">
                {{ $t('page.myReviews.heading') }}
            </router-link>
        </li>
        <li
            v-if="isLogged && showNav"
            @click="showLogoutPopup()"
        >
            <span>{{ $t('header.navbar.logout') }}</span>
        </li>
    </ul>
</template>

<script>
    import ArrowLeftIcon from 'vue-feather-icons/icons/ArrowLeftIcon';
    import { mapGetters, mapMutations } from 'vuex';

    export default {
        name: 'MyAccountNavbar',
        components: {
            ArrowLeftIcon,
        },
        props: {
            location: {
                type: String,
                default: 'sidebar',
            },
        },
        computed: {
            ...mapGetters('auth', ['isLogged']),
            isResponsive() {
                return this.$screen.width < 1024;
            },
            isMobile() {
                return this.$screen.breakpoint == 'md';
            },
            showNav() {
                return (
                    this.location == 'hamburger-menu' && this.$screen.breakpoint == 'md'
                );
            },
        },

        methods: {
            ...mapMutations('general', ['setModal']),
            isActive(routeName) {
                return (
                    this.$route.name === routeName ||
                    (this.$route.name === 'DetaliiComanda' && routeName === 'Comenzi')
                );
            },
            toggleMyNav() {
                this.$emit('toggleMyNav');
            },
            showLoginPopup() {
                this.setModal({
                    show: true,
                    type: 'login'
                });
            },
            showLogoutPopup() {
                this.setModal({
                    show: true,
                    type: 'logout'
                });
            },
            showRegistrationPopup() {
                this.setModal({
                    show: true,
                    type: 'registration'
                });
            },
        },
    };
</script>
