<template>
    <div class="list mt-4">
        <p
            v-if="!vouchers"
            class="sm-sbold"
        >
            {{ $t('page.myPromoCodes.noResult') }}
        </p>
        <div
            v-for="voucher in vouchers"
            v-else
            :key="`my-voucher-${vouchers.indexOf(voucher)}`"
            class="item"
            :class="{ expired: voucher.status.value == 'expired' }"
        >
            <article>
                <div class="image voucher">
                    <img
                        :src="voucher.thumbnail"
                        :alt="voucher.name"
                    >
                    <span class="label">
                        {{
                            voucher.value ? voucher.value + ' Lei' : voucher.percentage + ' %'
                        }}
                    </span>
                </div>
                <div class="content">
                    <p class="sbold mb-2">
                        {{ voucher.name }}
                    </p>
                    <p class="sm-sbold code">
                        {{ $t('page.myPromoCodes.codeText') }}
                        {{
                            voucher.code
                                ? voucher.code.toUpperCase()
                                : $t('page.myPromoCodes.inProgressText')
                        }}
                    </p>
                    <div
                        class="status d-flex"
                        :class="voucher.status.value"
                    >
                        <span>{{ voucher.status.label }}</span>
                        <info-icon />
                    </div>
                    <p
                        v-if="voucher.ended_at"
                        class="sm-sbold mt-2"
                    >
                        {{
                            $t('page.myPromoCodes.expirationText', {
                                expiration: voucher.ended_at,
                            })
                        }}
                    </p>
                </div>
            </article>
            <div
                v-if="voucher.status.value == 'expired'"
                class="expired-layer"
            >
                <h4 v-if="voucher.status.value == 'expired'">
                    {{ voucher.status.label }}
                </h4>
            </div>
        </div>
    </div>
</template>
<script>
    import InfoIcon from 'vue-feather-icons/icons/InfoIcon';
    import { i18n } from 'vue-lang-router';
    import { mapActions, mapState } from 'vuex';

    export default {
        name: 'MyVouchersListing',
        components: {
            InfoIcon,
        },
        computed: {
            ...mapState('user', ['vouchers']),
            lang() {
                return i18n.locale;
            },
        },
        methods: {
            ...mapActions('user', ['getVouchers']),
            async loadData() {
                await this.getVouchers();
            },
        },
        created() {
            this.loadData();
        },
        watch: {
            lang: {
                handler() {
                    this.loadData();
                },
                deep: true,
            },
        },
    };
</script>

<style scoped lang="scss">
.my-vouchers {
  h4 {
    margin-bottom: 24px;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 0;
    border: none;
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }
    p {
      color: $text;
    }
    .item {
      position: relative;
      max-width: 263px;
      width: calc(25% - 48px / 4);
      @include media-breakpoint-between(xm, sm) {
        width: calc(50% - 8px);
      }
      @include media-breakpoint-down(xm) {
        width: 100%;
        max-width: 100%;
      }
      article {
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        box-shadow: none;
        padding: 0;
        position: relative;
        .image {
          overflow: hidden;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          transition-timing-function: ease-in;
          transition: 300ms;
          img {
            object-fit: cover;
          }
          .label {
            top: 12px;
            padding: 2px 8px;
            font-size: 12px;
            @include media-breakpoint-down(xm) {
              padding: 6px 12px;
              font-size: 16px;
            }
          }
        }
        .content {
          padding: 12px;
          border-left: 1px solid;
          border-bottom: 1px solid;
          border-right: 1px solid;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          border-color: $gray-light;
          transition-timing-function: ease-in;
          transition: 300ms;
          a {
            font-weight: 600;
          }
          p {
            height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 20px;
            color: $text;
            color: $black;
            font-size: 14px;
            @include media-breakpoint-down(xm) {
              height: 20px;
              -webkit-line-clamp: 1;
            }
            &.sm-sbold {
              color: $text;
              font-size: 12px;
              line-height: 18px;
              display: flex;
              justify-content: flex-end;
              flex-direction: column;
            }
          }
          .status {
            color: $gray-dark;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            align-items: center;
            svg {
              width: 16px;
              height: 16px;
              stroke: $gray-dark;
              @include media-breakpoint-between(sm, lg) {
                display: none;
              }
            }
          }
        }
        @include media-breakpoint-up(md) {
          &:hover {
            .image {
              filter: drop-shadow(0px -4px 6px $primary-25);
            }
            .content {
              border-color: $white;
              box-shadow: 0px 4px 16px $gray-light;
            }
          }
        }
      }
      &.expired {
        .article {
          opacity: 0.7;
        }
        .expired-layer {
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(255, 255, 255, 0.7);
          height: 100%;
          width: 100%;
          overflow: hidden;
          h4 {
            text-transform: uppercase;
            color: $red-light;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
