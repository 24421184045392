<template>
    <div class="loading-container">
        <div class="spinner">
            <div class="loading-spinner-one" />
            <div class="loading-spinner-two" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LoadingSpinner',
    };
</script>
<style lang="scss" scoped>
.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  position: relative;
  opacity: 0.6;
  z-index: 999;
  &.fixed {
    left: 50%;
    top: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
  }
  .spinner {
    background: url("/images/emblem.svg") no-repeat center center;
    width: 40px;
    height: 40px;
    animation: spin 0.8s linear infinite;
    @include media-breakpoint-down(md) {
      width: 35px;
      height: 35px;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
