<template>
    <div class="container">
        <loading-spinner
            v-if="showLoader"
            class="fixed"
        />
        <b-row>
            <sidebar />
            <b-col
                cols="12"
                md="9"
                class="my-vouchers mtd-40"
                :class="{ transparent: showLoader }"
            >
                <h4>
                    {{ $t('page.myPromoCodes.heading') }}
                </h4>
                <my-vouchers-listing />
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    import Sidebar from '@/components/page/Sidebar';

    import LoadingSpinner from '../components/loaders/LoadingSpinner';
    import MyVouchersListing from './myVouchers/MyVouchersListing';
    export default {
        name: 'MyVouchers',
        components: {
            Sidebar,
            MyVouchersListing,
            LoadingSpinner
        },
        computed: {
            ...mapState('user', ['loading', 'vouchers']),
            showLoader() {
                return this.vouchers && Object.keys(this.vouchers).length == 0 && this.loading;
            },
        },
        metaInfo() {
            return {
                title: this.$t('meta.myAccount.vouchersTitle'),
                meta: [
                    {
                        name: 'robots',
                        content: 'noodp',
                    },
                    {
                        vmid: 'description',
                        name: 'description',
                        content: '',
                    },
                    {
                        vmid: 'keywords',
                        name: 'keywords',
                        content: '',
                    },
                    {
                        vmid: 'og:title',
                        property: 'og:title',
                        content: '',
                    },
                    {
                        vmid: 'og:url',
                        property: 'og:url',
                        content: '',
                    },
                    {
                        vmid: 'og:description',
                        property: 'og:description',
                        content: '',
                    },
                    {
                        vmid: 'og:image',
                        property: 'og:image',
                        content: '',
                    },
                ],
                link: [
                    {
                        rel: 'canonical',
                        href: '',
                    },
                ],
            };
        },
    };
</script>
